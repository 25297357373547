.home {
    width: 100%;
    height: 100vh;
    background: transparent url('../../assets/images/mesh-gradient-bg-01.png') 0% 0% no-repeat padding-box;
    background-size: cover;
    padding: 0 4rem;
        
    h1 {
        font-family: "IBM Plex Sans", sans-serif;
        font-size: 3.5rem;
        font-weight: 700;
        color: #fff;
        margin-left: 1rem;
        width: 50%;
        padding-top: 15rem;
    }

    p {
        font-family: "IBM Plex Sans", sans-serif;
        font-size: 2rem;
        font-weight: 400;
        color: #fff;
        margin-top: 2rem;
        margin-left: 1rem;
        width: 60%;
    }

    .socialMedia {
        position: absolute;
        right: 4rem;
        bottom: 8rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        svg {
            color: #fff;
            font-size: 1.5rem;
            transition: color 0.3s;
            cursor: pointer;

            &:hover {
                box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
            }
        }
    }
}

@media (max-width: 1024px) {
    .home {
        padding: 3rem;
        height: auto;
        
        h1 {
            font-size: 3rem;
            width: 90%;
            padding-top: 10rem;
        }

        p {
            font-size: 1.5rem;
            width: 90%;
        }

        .socialMedia {
            right: 3rem;
            bottom: 12rem;
        }
    }
}

@media (max-width: 425px) {
    .home {
        padding: 3rem;
        height: auto;
        
        h1 {
            font-size: 2.5rem;
            width: 100%;
            padding-top: 10rem;
        }

        p {
            font-size: 1.5rem;
            width: 100%;
        }

        .socialMedia {
            right: 3rem;
            bottom: 4rem;
        }
    }
}