.logo {
    width: 70%;
    border-radius: 0.25rem;

    img {
        width: 70%;
        height: 70%;
    }
}

@media (max-width: 1024px) {
    .logo {
        width: 50%;

        img {
            width: 60%;
            height: 60%;
        }
    }
}

@media (max-width: 768px) {
    .logo {
        width: 80%;

        img {
            width: 60%;
            height: 40%;
        }
    }
}