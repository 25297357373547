.containerFooter {
    background-color: var(--black-800);
    padding: 5rem 5rem 3rem 5rem; 

    .listFooter {
        display: flex;

        p {
            max-width: 100%;
            text-wrap: nowrap;
            color: #FFFFFF;
            font-size: 1.25rem;
            font-family: 'IBM Plex Sans', sans-serif;
            margin-bottom: 1rem;
        }

        ul {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                margin-bottom: 1rem;
            }
        }

        a {
            color: var(--gray-400);
            font-size: 1.125rem;
            font-family: 'IBM Plex Sans', sans-serif;
            text-wrap: nowrap;

            &:hover {
                color: #FFFFFF;
            }
        }
    }

    .boxFinal {
        display: flex;
        justify-content: space-between;

        p {
            width: 33%;
            color: var(--gray-400);
            font-size: 1.125rem;
            font-family: 'IBM Plex Sans', sans-serif;
        }

        .boxImg {
            width: 33%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 10rem;

            img {
                width: 5rem;
                height: 4.5rem;
            }
        }

        .boxMedia {
            display: flex;
            justify-content: flex-end;
            width: 33%;

            .socialMediaFooter {
                display: flex;
                justify-content: flex-end;
                gap: 1.5rem;
                width: 100%;
                svg {
                    color: #fff;
                    font-size: 1.5rem;
                    transition: color 0.3s;
                    cursor: pointer;
        
                    &:hover {
                        box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
                    }
                }
            }
        }

    }

}

@media (max-width: 768px) {
    .containerFooter {
        padding: 3rem 3rem 1rem 3rem;

        .listFooter {
            gap: 1.5rem;

            p {
                font-size: 1rem;
            }

            ul {
                gap: 1.5rem;
            }

            a {
                text-wrap: pretty;
            }
        }

        .boxFinal {
            p {
                font-size: 1rem;
            }

            .boxImg {
                height: 5rem;

                img {
                    width: 3rem;
                    height: 2.5rem;
                }
            }

            .boxMedia {
                justify-content: flex-start;
            }
        }
    }
}

@media (max-width: 450px) {
    .containerFooter {
        padding: 3rem 1.5rem 1rem 1.5rem;

        .listFooter {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            p {
                font-size: 1rem;
            }

            ul {
                display: flex;
                flex-direction: row;
                gap: 1.5rem;
            }
        }

        .boxFinal {
            p {
                font-size: 1rem;
            }

            .boxImg {
                height: 5rem;

                img {
                    width: 3rem;
                    height: 2.5rem;
                }
            }

            .boxMedia {
                justify-content: flex-start;
            }
        }
    }
}