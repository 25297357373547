.contentCarousel {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    height: 100vh;
    background-color: var(--black);
    padding: 15rem 8rem 8rem 8rem;

    .container {
        width: 45%;

        h1 {
            color: #FFFFFF;
            font-size: 3.7rem;
            font-weight: 600;
            font-family: 'Ibm Plex Sans', sans-serif;
            margin-bottom: 2.5rem;
        }

        .boxCarousel {
            width: 100%;
        }
    
        .tabText {
            color: var(--gray-600);
            font-size: 1.85rem;
            font-weight: 400;
        }
    
        .active {
            color: var(--water-green-600);
            font-size: 1.85rem;
            font-weight: 400;
        }

        .tabContent {
            p {
                margin-top: 1rem;
                font-size: 1.15rem;
            }

            h2 {
                font-size: 2rem;
            }
        }

        .tabList {
            h2 {
                font-size: 2rem;
            }

            h3 {
                font-size: 1.15rem;
                margin-top: 1rem;
                font-weight: 400;
            }

            .contentList {
                .text {
                    font-size: 1rem;
                    margin-left: 2.3rem;
                }

                .boxMarcador {
                    display: flex;
                    margin-top: 1rem;
                    align-items: center;

                    p {
                        color: var(--blue-400);
                        font-size: 1rem;
                    }

                    img {
                        width: 1.5rem;
                        height: 1.75rem;
                        margin-right: 1rem;
                    }
                }
            }
        }
    }

    .boxImages {
        width: 55%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
       
        img {
            width: 78%;
            height: 90%;
        }
    }
}

.contentCarouselComponent {
    position: static;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    height: 100vh;
    background-color: var(--black);
    padding: 15rem 8rem 8rem 8rem;
    z-index: 3;

    .container {
        width: 45%;
        z-index: 3;

        h1 {
            color: #FFFFFF;
            font-size: 3.7rem;
            font-weight: 600;
            font-family: 'Ibm Plex Sans', sans-serif;
            margin-bottom: 2.5rem;
        }

        .boxCarousel {
            width: 100%;
        }
    
        .tabText {
            color: var(--gray-600);
            font-size: 1.85rem;
            font-weight: 400;
        }
    
        .active {
            color: var(--water-green-600);
            font-size: 1.85rem;
            font-weight: 400;
        }

        .tabContent {
            p {
                margin-top: 1rem;
                font-size: 1.15rem;
            }

            h2 {
                font-size: 2rem;
            }
        }

        .tabList {
            h2 {
                font-size: 2rem;
            }

            h3 {
                font-size: 1.15rem;
                margin-top: 1rem;
                font-weight: 400;
            }

            .contentList {
                .text {
                    font-size: 1rem;
                    margin-left: 2.3rem;
                }

                .boxMarcador {
                    display: flex;
                    margin-top: 1rem;
                    align-items: center;
                    
                    p {
                        color: var(--blue-400);
                        font-size: 1rem;
                    }
                    
                    img {
                        width: 1.5rem;
                        height: 1.75rem;
                        margin-right: 1rem;
                    }
                }
            }
        }
    }

    .boxImages {
        width: 55%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        z-index: 3;
        
        img {
            width: 78%;
            height: 90%;
        }
    }
}

.contentCarouselComponent::before {
    content: '';
    position: absolute;
    top: 30%;
    left: 0;
    width: 100%;
    height: 70%;
    background: url('../../assets/images/mesh-gradient-bg-03/mesh-gradient-bg-03@2x.png') no-repeat center center;
    background-size: cover;
    opacity: 0.7; /* Ajuste a opacidade conforme necessário */
}

@media (max-width: 1024px) {
    .contentCarousel {
        padding: 8rem 4rem 2rem 4rem;
        flex-direction: column;
        align-items: center;
        height: auto;
        
        .boxImages {
            padding: 2rem 0;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .container {
            width: 100%;
            height: auto;
            padding: 0 2rem;

            h1 {
                font-size: 3rem;
            }
        }
    }

    .contentCarouselComponent {
        padding: 15rem 4rem 4rem 4rem;
        flex-direction: column;
        align-items: center;
        height: auto;

        .container {
            width: 100%;
            height: auto;
            padding: 0 2rem;

            h1 {
                font-size: 3rem;
            }
        }

        .boxImages {
            padding: 2rem 0;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media (max-width: 768px) {
    .contentCarouselComponent {
        padding: 15rem 2rem 2rem 2rem;

        .container {
            width: 100%;
            padding: 0 1rem;

            h1 {
                font-size: 2.5rem;
            }
        }

        .boxImages {
            display: none;
        }
    }
}

@media (max-width: 425px) {
    .contentCarousel {
        padding: 8rem 1.5rem 1.5rem 1.5rem;

        .boxImages {
            display: none;
        }

        .container {
            width: 100%;
            padding: 0 1rem;

            h1 {
                font-size: 2.5rem;
            }
        }
    }
}