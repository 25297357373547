.header {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 4rem 1rem 4rem;
    background-color: transparent;
    color: #fff;
    z-index: 3;

    &::after {
        content: "";
        position: absolute;
        top: 7rem;
        left: 5%;
        width: 90%;
        height: 1px;
        background: #FFFFFF;
    }

    .logo {
        width: 20%;
        border-radius: 0.25rem;

        img {
            width: 70%;
            height: 70%;
        }
    }

    .navBar {
        display: flex;
        width: 80%;
        gap: 2rem;

        a {
            color: #fff;
            text-decoration: none;
            font-family: "IBM Plex Sans", sans-serif;
            font-size: 1rem;
            font-weight: 400;

            &[itemID='true'] {
                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 2px;
                    background: #fff;
                    transition: width 0.3s;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .header {
        padding: 1rem 3rem 1rem 3rem;

        .logo {
            width: 30%;

            img {
                width: 60%;
                height: 60%;
            }
        }
    }
}

@media (max-width: 768px) {
    .header {
        padding: 1rem 2rem 1rem 2rem;

        .logo {
            width: 40%;

            img {
                width: 60%;
                height: 60%;
            }
        }

        .menuIcon {
            width: 60%;
            font-size: 2rem;

            display: flex;
            justify-content: flex-end;
        }
    }
}

@media (max-width: 450px) {
    .header {
        padding: 1rem 1rem 1rem 1rem;

        .logo {
            width: 60%;

            img {
                width: 65%;
                height: 65%;
            }
        }

        .menuIcon {
            width: 40%;
            font-size: 2rem;

            display: flex;
            justify-content: flex-end;
        }
    }
}