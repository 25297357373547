* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  :root {
    --black: #000000;
    --black-800: #171717;
    --black-200: #373737;

    --blue-800: #0D58A5;
    --blue-700: #0F6BCA;
    --blue-600: #1C8BDC;
    --blue-500: #1B89DB;
    --blue-400: #2AADF0;

    --water-green-600: #29BE9F;
    --water-green-500: #1EE4BB;

    --green-600: #12CE5D;

    --gray-800: #5A5A5A;
    --gray-700: #707070;
    --gray-650: #0000008C;
    --gray-600: #666666;
    --gray-500: #A8A8A8;
    --gray-400: #9F9F9F;
    --gray-300: #B4B4B4;
  }
  
  @media (max-width: 1080px) {
    html {
      font-size: 93.75%;
    }
  }
  
  @media (max-width: 728px) {
    html {
      font-size: 87.5%;
    }
  }
  
  body {
    background: var(--white);
    color: var(--black-800);
  }
  
  body,
  input,
  textarea,
  select,
  button {
    font: 400 1rem "IBM Plex Sans", sans-serif;
  }
  
  button {
    cursor: pointer;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }