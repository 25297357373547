.container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--black);

    .contentForm {
        padding: 5rem;

        h1 {
            font-size: 2.5rem;
            color: #FFFFFF;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1.25rem;
            color: #FFFFFF;
            max-width: 98%;
        }
    }

    .boxImg {
        max-width: 50%;

        img {
            width: 80%;
            height: auto;
        }
    }

    label {
        font-size: 1.25rem;
        color: #FFFFFF !important;
        margin-bottom: 0.5rem;
    }

    .ant-input-textarea-show-count {
        .ant-input-data-count {
            color: #FFFFFF !important;
        }
    }
}

@media (max-width: 1024px) {
    .container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;

        .contentForm {
            padding: 4rem;

            h1 {
                font-size: 2rem;
            }

            p {
                font-size: 1rem;
            }
        }

        .boxImg {
            width: 100%;
            display: flex;
            justify-content: center;

            margin: 2rem 0;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

@media (max-width: 768px) {
    .container {
        .contentForm {
            padding: 9rem 2rem 3rem 2rem;

            h1 {
                font-size: 1.5rem;
            }

            p {
                font-size: 1rem;
            }
        }

        .boxImg {
            display: none;
        }
    }
}

@media (max-width: 425px) {
    .container {
        .contentForm {
            padding: 9rem 2rem 3rem 2rem;

            h1 {
                font-size: 1.5rem;
            }

            p {
                font-size: 0.75rem;
            }
        }

        .boxImg {
            display: none;
        }
    }
}