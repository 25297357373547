.container {
    height: 100vh;
    position: relative;
    background-color: var(--black);
    overflow: hidden;
}

.container::before {
    content: '';
    position: absolute;
    top: 30%;
    left: 0;
    width: 100%;
    height: 70%;
    background: url('../../assets/images/mesh-gradient-bg-02/mesh-gradient-bg-02@2x.png') no-repeat center center;
    background-size: cover;
    opacity: 0.7; /* Ajuste a opacidade conforme necessário */
    z-index: 1;
}

.boxTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;

    h1 {
        font-size: 2.5rem;
        color: #FFFFFF;
        margin-bottom: 1rem;
    }

    p {
        font-size: 1.25rem;
        color: #FFFFFF;
        text-align: center;

        max-width: 40%;
    }
}

.contentCards {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding: 2rem;
    z-index: 2;
    margin-top: 12rem;
}

.cardServices {
    width: 25rem !important;
    height: 25rem;

    background-color: var(--black);
    border: none;
    border-radius: 2rem;
    transition: all 0.3s;
    
    cursor: default;

    &:hover {
        transform: scale(1.05);
        transition: all 0.3s;
        box-shadow: 0 0 5px 1px var(--gray-500);
    }

    .titleCard {
        font-size: 1.25rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }

    .descriptionCard {
        font-size: 1rem;
        font-weight: 300;
        color: #FFFFFF;
    }

    .boxBtn {
        display: flex;
        justify-content: flex-end;
        align-items: center; /* Alinha o conteúdo verticalmente ao centro */
        padding: 1rem; /* Ajuste o padding conforme necessário */
        border-bottom-left-radius: 2rem; /* Mantém os cantos arredondados no rodapé */
        border-bottom-right-radius: 2rem; /* Mantém os cantos arredondados no rodapé */
        position: absolute; /* Para posicionar o botão no canto inferior direito */
        bottom: 0.75rem;
        right: 0.75rem;
    }
}

//style form component de pagina
.containerComponent {
    height: fit-content;
    position: relative;
    background-color: var(--black);
    overflow: hidden;
}

.containerComponent::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/images/mesh-gradient-bg-04/mesh-gradient-bg-04@2x.png') no-repeat center center;
    background-size: cover;
    opacity: 0.7; /* Ajuste a opacidade conforme necessário */
    z-index: 1;
}

.boxTitleComponent {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 12rem;
    z-index: 4;

    h1 {
        font-size: 2.5rem;
        color: #FFFFFF;
        margin-bottom: 1rem;
    }

    p {
        font-size: 1.25rem;
        color: #FFFFFF;
        text-align: center;

        max-width: 40%;
    }
}

.contentCardsComponent {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2.5rem;
    padding: 2rem;
    z-index: 2;
    margin-top: 3rem;
}

.cardServicesComponent {
    width: 90% !important;
    height: 30rem;

    background-color: var(--black);
    border: none;
    border-radius: 2rem;
    transition: all 0.3s;
    
    cursor: default;

    &:hover {
        transition: all 0.3s;
        box-shadow: 0 0 5px 1px var(--gray-500);
    }

    .titleCardComponent {
        font-size: 1.25rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }

    .descriptionCard {
        font-size: 1rem;
        font-weight: 300;
        color: #FFFFFF;
    }

    .boxBtnComponent {
        display: flex;
        justify-content: flex-start;
        align-items: center; /* Alinha o conteúdo verticalmente ao centro */
        padding: 1rem; /* Ajuste o padding conforme necessário */
        border-bottom-left-radius: 2rem; /* Mantém os cantos arredondados no rodapé */
        border-bottom-right-radius: 2rem; /* Mantém os cantos arredondados no rodapé */
        position: absolute; /* Para posicionar o botão no canto inferior direito */
        bottom: 0.75rem;
        left: 0.75rem;
    }

    .contentDescriptiom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .boxDescription {
            width: 58%;
        }

        .boxImg {
            width: 38%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: auto;
                border-radius: 2rem;
            }
        }
        
    }
}

//responsive

@media (max-width: 1024px) {
    .container {
        width: 100%;
        height: auto;

        .boxTitle {
            p {
                max-width: 60%;
                z-index: 4;
            }
        }
    }

    .contentCards {
        padding: 2rem;
        display: block;
        width: 100%;

        .cardServices {
            margin: 0 auto;
        }
    }

    .containerComponent {
        width: 100%;
        height: auto;

        .boxTitleComponent {
            p {
                max-width: 80%;
                z-index: 4;
            }
        }
    }

    .contentCardsComponent {
        padding: 2rem;
        width: 100%;

        .cardServicesComponent {
            display: flex !important;
            height: 35rem;
        }

        .boxImg {
            display: none !important;
        }

        .contentDescriptiom {
            flex-direction: column;

            .boxDescription {
                width: 100%;
            }
        }
    }
}

@media (max-width: 425px) {
    .container {
        width: 100%;
        height: auto;

        .boxTitle {
            p {
                max-width: 80%;
                z-index: 4;
            }
        }
    }

    .contentCards {
        padding: 2rem;
        display: block;
        width: 100%;

        .cardServices {
            margin: 0 auto;
        }
    }

    .containerComponent {
        width: 100%;
        height: auto;
    }
}